@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.official-services-main {
    .official-services-item:last-of-type {
        border-bottom: none;
    }
}

.official-services-additional {
    .official-services-item:last-of-type {
        border-bottom: none;
    }
}

.official-services-item {
    border-bottom: 1px solid #e1e3e6;
    margin-top: 15px;

    &__name {
        @include clearfix();

        font-size: $font-size;
        margin-bottom: 15px;
        color: $text-light;
        line-height: normal;

        .link-style {
            line-height: normal;
            font-size: $font-size;
            color: $text-lighter;
        }

        &__left {
            float: left;
            width: 80%;

            &__name {
                max-width: 80%;
            }

            &__duration {
                font-size: $font-size-sm;
            }
        }

        &__add-text {
            clear: both;
            font-size: $font-size-xs;
            color: $text-lighter;
        }
    }

    .custom-control-label {
        display: block;
    }
}

.official-services-single {
    padding: 10px 20px;
    border-radius: $border-radius;
    box-shadow: $package-shadow;
    margin-bottom: 20px;

    &__name {
        @include clearfix();

        line-height: 17px;
        font-size: $font-size;
        margin-bottom: 5px;
        color: $text-lighter;
        padding-left: 50px;

        &__left {
            float: left;
            width: 80%;

            &__name {
                max-width: 100%;
                float: left;
            }
        }

        &__right {
            float: right;

            .link-style {
                line-height: normal;
                font-size: $font-size;
                color: $text-lighter;
            }
        }
    }

    &__header {
        margin-bottom: 20px;
        font-size: 14px;
        color: $text-light;
        padding-left: 50px;
        border-bottom: 1px solid $brand-gray-3;
        padding-bottom: 10px;
    }

    &__total {
        @include clearfix();

        margin-top: 20px;
        font-size: $font-size;
        color: $text-light;
        padding-left: 50px;
        border-top: 1px dashed $brand-gray-3;
        padding-top: 10px;
    }

    label {
        display: block;
        padding-left: 0;
    }

    .custom-checkbox-label {
        top: 50%;
        transform: translateY(-50%);
    }
}
