@import 'assets/styles/base/_variables';

$margin: 20px;
$margin-negative: -$margin;

.activity-container {
    position: relative;
    margin: 0 $margin-negative;

    & > .container {
        padding: 0;
    }
}

.activity-list {
    padding: 50px $margin 40px;
    background-color: rgba(225, 227, 230, 0.15);
    overflow-y: auto;
    height: calc(100vh - 77px - 48px);

    &__date-segment {
        font-weight: $font-weight-semi-bold;
        background-color: #f0f1f2;
        padding: 2px 10px;
        text-transform: uppercase;
        display: table;
        margin: 15px auto 15px;
        color: $text-lighter;
        font-size: $font-size-sm;
    }

    &--no-action {
        margin-bottom: -53px;
        height: calc(100vh - 63px);
    }
}

.activity-container-fixed {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 20;

    .container {
        padding: 0;
    }
}

.activity-container-user {
    padding: 10px $margin 15px;
    color: $text-light;
    width: 100%;
    border-bottom: 1px solid $border-color;

    &__name {
        font-size: $font-size-2;
        font-weight: $font-weight-semi-bold;
    }

    &__car{
        margin-top: 10px;
    }
}

.activity-item {
    margin-bottom: 30px;
    display: table;
    width: 100%;

    &__received{
        text-align: left;
        float: left;
        max-width: 80%;
    }

    &__sent{
        text-align: right;
        float: right;
        max-width: 80%;
    }

    &__time {
        font-size: 11px;
        color: $text-lighter;
    }

    &__user {
        font-weight: $font-weight-semi-bold;
        color: $text-light;
        line-height: 22px;
    }

    &__message {
        color: $text-light;
    }

    &__bottom {
        font-size: $font-size-sm;
    }
}

.new-events-flag {
    border-top: 1px solid $brand-primary;
    position: relative;
    margin: 20px 0;

    span {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -55%);
        line-height: normal;
    }

    span:first-child {
        display: block;
        width: 100px;
        background: white;
        height: 14px;
        z-index: 1;
    }
    span:last-child {
        background: rgba(225, 227, 230, 0.15);
        padding: 0 25px;
        font-size: 10px;
        color: $brand-primary;
        z-index: 2;
    }
}
