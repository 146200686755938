@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.analytic-tab-title{
    color: $text-light;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 10px;
}

.analytic-tab{
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px $brand-gray-3;
    margin-bottom: 25px;
}

.analytic-tab-header{
    .analytic-tab-content__package-stat {
        margin-bottom: 0;
    }
}

.analytic-tab-content{

    &--expandable{
        background-color: rgba(225, 227, 230, 0.3);
        padding: 10px 15px;
    }

    &__package-stat {
        margin-bottom: 20px;

        .label {
            color: $text-light;
            font-weight: $font-weight-semi-bold;
            margin-bottom: 10px;
            font-size: $font-size-xs;
        }

        &__item{
            text-align: center;
            padding: 2px;
            border-right: 1px solid #FFF;
            border-left: 1px solid #FFF;

            &:first-child{
                border-left: none;
            }
            &:last-child{
                border-right: none;
            }

            &--warning{
                color: $brand-warning;
            }
            &--success{
                color: $brand-success;
            }
            &--danger{
                color: $brand-danger;
            }

            .icon {
                font-size: $font-size-2;
            }

            .value {
                font-size: $font-size-4;
                margin-left: 10px;
                color: rgba(67, 95, 113, 0.8);
            }
        }
    }

}

.filter-statistics-button{
    line-height: 28px;
}
