@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.red-light-blink {
	animation: blinker 0.8s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
	width: 15px;
	height: 15px;
	background-color: $brand-danger;
	display: inline-block;
	border-radius: 15px;
	margin-right: 5px;

}

@keyframes blinker {
	from { background-color: $brand-danger; }
	to { background-color: transparent; }
}

.audio-recording-controls{
	@include clearfix();

	/*&__close{
		font-size: 32px;
		color: $brand-danger;
		transform: rotate(45deg)
	}
	&__save{
		font-size: 32px;
		color: $brand-success;
	}*/

	&__time{
		line-height: 32px;
        color: $text-light;
		font-size: $font-size-4;
        margin-bottom: 20px;
        text-align: center;

        &--has-file{
            color: $brand-primary;
        }
	}
}

.audio-record-control{
    text-align: center;
    color: $text-lighter;
    font-size: 10px;
    font-weight: $font-weight-semi-bold;
}

.audio-record-control-icon{
    width: 32px;
    height: 32px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e1e3e6;
    padding: 10px;
    border-radius: 50px;
    text-align: center;
    line-height: 32px;
    color: $text-lighter;
    font-size: 10px;
    font-weight: $font-weight-semi-bold;
    position: relative;
    margin: 0 auto 15px;
    -webkit-appearance: none;
    display: block;
    background: #FFF;
    outline: none;

    i {
        font-weight: $font-weight-regular;
        font-size: 12px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
    }

    &__rec {
        transform: scale(1.4);

        i {
            transform: translate(-50%, -50%) scale(0.7);
            font-size: 16px;
            color: $brand-danger;
        }

        &--has-file{
            i {
                color: $secondary-gray;
            }
        }
    }

    &__cancel {

    }

    &__stop {
        &--has-file{
            color: $text-light;
        }
    }
}
