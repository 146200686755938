@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.analytics-export {
    background: $brand-success;
    border-radius: 50px;
    color: $btn-empty;
    display: inline-block;
    padding:10px 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.analytic-tab.analytic-tab-top-by-revenue {

    .analytic-tab-header {
        padding: 20px 15px 15px;
        color: $text-light;
        cursor: pointer;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-sm;

        @media (min-width: $screen-sm-min) {
            font-size: $font-size;
        }

        .chevron {
            float: right;
            color: $text-lighter;
        }
    }

    .analytic-tab-content {
        &__package-stat {
            margin-bottom: 5px;

            &:first-child {
                margin-bottom: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .label {
                font-size: $font-size-sm;

                @media (min-width: $screen-sm-min) {
                    font-size: $font-size;
                }
            }

            &__item {
                .value {
                    font-size: $font-size-sm;
                    margin: 0;
                    font-weight: $font-weight-semi-bold;

                    @media (min-width: $screen-sm-min) {
                        font-size: $font-size;
                    }
                }

                .header {
                    font-size: $font-size-xs;
                    font-weight: $font-weight-regular;

                    @media (min-width: $screen-sm-min) {
                        font-size: $font-size-sm;
                    }
                }
            }
        }
    }

}

.analytic-enhanced-date-picker {
    margin-bottom: 20px;

    .form-group {
        .btn {
            margin: 0;
            padding: 12px 16px 12px 16px !important;
        }
    }
}
