.service-tab {
	padding: 15px 15px 15px;
	background-color: #FFFFFF;
	text-align: center;
	border: 1px solid $border-color-light;
	border-radius: 8px;
	margin: 10px 5px;
	color: $text-color;
	line-height: normal;
	display: block;

	&__icon {
		font-size: 30px;
	}

	&__tip {
		font-weight: $font-weight-light;
		font-size: 12px;
	}
}

.services-save-footer{
	bottom: $footer-icons-height;
	background-color: #FFFFFF;
    padding: 0 $submit-button-padding;

    @media (min-width: $screen-sm-min) {
        bottom: $footer-icons-height-sm;
    }
}

.list-of-prices {

	margin-bottom: 50px;

	&__item{
		padding: 15px 15px 0;
		border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
		margin-bottom: 20px;
        font-size: $font-size-sm;
        color: $text-lighter;

		&__footer {
			@include clearfix();
			border-top: 1px solid $border-color-light;
			margin: 15px -15px 0;

			& > *{
				padding: 10px 0;
			}
		}
	}

}
