@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

$file-upload-item-height: 75px;

.files-upload-list {
    @include clearfix();

    padding: 10px 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.file-upload-item {
    width: $file-upload-item-height;
    height: $file-upload-item-height;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;

    img {
        width: $file-upload-item-height;
        height: $file-upload-item-height;
        vertical-align: baseline;
        object-fit: cover;
    }

    &:first-child {
        margin-left: 0;
    }

    &__icons {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        width: 100%;
        text-align: center;
        line-height: $file-upload-item-height;
        color: #FFF;
        z-index: 1;
    }

    &__action {
        position: absolute;
        background-color: #FFF;
        border-radius: 50px;
        width: 27px;
        height: 27px;
        text-align: center;
        line-height: 27px;
        font-size: 8px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
        color: $text-lighter;
        z-index: 2;
    }

    &__remove {
        top: -5px;
        right: -5px;
    }

    &__download {
        top: -5px;
        right: 35px;
        color: $text-color !important;
    }

    &__audio {
        width: $file-upload-item-height;
        height: $file-upload-item-height;
        text-align: center;
        background-color: $brand-gray-2;
        position: relative;

        i {
            font-size: 33px;
            color: #FFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 9999999 !important;

    .ril__outer {
        background-color: #000;
    }
}

.file-upload-controls {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;
}

.file-upload-icon {
    display: inline-block;
    width: calc(100% / 3);
    text-align: center;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-sm;
    color: $brand-primary;
    cursor: pointer;

    label {
        cursor: pointer;
    }

    i {
        font-size: 29px;
        color: $text-lighter;
    }
}
