@import 'assets/styles/base/_variables';

.undo-message{
    padding-right: 50px;

    &__undo{
        line-height: normal;
        position: absolute;
        right: 0;
        height: 100%;
        top: -5px;
        padding: 15px 10px;
        z-index: 99999999;
        font-size: $font-size;
	}
}
