@import 'assets/styles/base/_variables';

.activity-date-segment {
    font-weight: $font-weight-semi-bold;
    background-color: $brand-gray-3;
    padding: 2px 10px;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 5px;
    color: $text-lighter;
}
