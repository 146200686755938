@import "assets/styles/base/_variables";

.editable-entry {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    font-size: 12px;
    margin-bottom: 15px;

    &__list{
        padding: 15px;

        .left {
            color: $text-lighter;
        }
        .right {
            font-size: $font-size;
            font-weight: $font-weight-semi-bold;
            color: $text-light;
        }
    }

    &__footer {
        border-top: 1px solid #eaeaea;
        padding: 10px 0;
        text-align: center;
    }
}
