@import "assets/styles/base/_variables";

.package-files-vertical{

	&__title{
		border-bottom: 1px solid $border-color-light;
		padding-bottom: 5px;
	}

	&__empty{
		margin: 15px auto;
		text-align: center;
		color: $text-lighter;
	}
}

.available-works{
	font-size: 30px;
	vertical-align: middle;
	margin-right: 10px;
}
.available-works-line-height{
	line-height: 30px;
}

.edit-link {
    font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;

    i {
        font-size: $font-size-2;
        vertical-align: text-top;
    }
}
