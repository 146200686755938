@import 'assets/styles/base/_variables';

.tires-import {

    .file-upload {
        position: relative;
        padding: 50px 20px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BDC0C9FF' stroke-width='3' stroke-dasharray='20' stroke-dashoffset='50' stroke-linecap='square'/%3e%3c/svg%3e");
        text-align: center;
        margin-bottom: 30px;
        cursor: pointer;

        &:not(.disabled) {
            &:hover {
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23656C7EFF' stroke-width='3' stroke-dasharray='20' stroke-dashoffset='50' stroke-linecap='square'/%3e%3c/svg%3e");
            }
        }

        &.disabled {
            cursor: not-allowed;
        }

        label {
            color: $text-lighter;
            font-size: $font-size-2;
        }

        input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;

            &:disabled {
                cursor: not-allowed;
            }
        }
    }
}
