.system-messages-list {
    &__item {
        border-bottom: 1px solid #e1e3e6;
        padding: 16px 0 16px 6px;
        cursor: pointer;
        font-weight: bold;
        .seen-title {
            color: #8f9ca5;
            font-weight: lighter;
        }
    }
    &__empty-messages {
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        left: 0;
        width: 100%;
    }

    &__content-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    &__content-modal {
        width: 85%;
        background-color: #fff;
        border-radius: 10px;
        padding: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        max-height: 100%;
    }

    &__content-modal-title {
        border-bottom: 1px solid #e1e3e6;
        margin: 10px 0 10px;
        font-size: large;
        padding-bottom: 10px;
    }

    &__content-modal-content {
        overflow: auto;
        max-height: 50vh;
        iframe {
            width: 100%;
        }
        img {
            width: 100% !important;
        }
    }

    &__date {
        font-weight: lighter !important;
        font-size: smaller;
        color: #8f9ca5;
    }

    &__marker {
        &-1 {
            border-left: 3px solid cornflowerblue;
        }

        &-2 {
            border-left: 3px solid darkred;
        }

        &-3 {
            border-left: 3px solid orange;
        }

        &-4 {
            border-left: 3px solid cadetblue;
        }

        &-5 {
            border-left: 3px solid mediumpurple;
        }
    }
}

.system-messages-list__content-container {
    .icon-cancel {
        float: right;
        cursor: pointer;
    }
}
