.settings-tab .mastercheck-options-wrapper:last-child{
    margin-bottom: 20px;
}

.settings-tab .mastercheck-options {
    width: 80px;
}

.settings-tab .mastercheck-options-label{
    line-height: 27px;
}

.mastercheck-layout-toggle {
    margin-top: 20px;
}
