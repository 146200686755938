@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.analytic-tab.analytic-tab-statistics {

    .analytic-tab-header {
        padding: 10px 15px;
        cursor: pointer;

        .statistic-chevron-default {
            i {
                float: right;
                color: #8f9ca5;
                font-size: 12px;
            }

            &.mt-22 i{
                margin-top: 22px;
            }

            &.close {
                i {
                    display: inline-block;
                    transform: rotate(180deg);
                }
            }
        }

        .sum-prices {
            font-size: 22px;
            text-align: right;
            font-weight: $font-weight-semi-bold;

            .accepted {
                color: $brand-success;
            }

            .requested {
                color: $brand-primary;
            }

            .rejected {
                color: $brand-danger;
            }

            .gray {
                color: $text-lighter;
            }

            .label {
                color: $text-light;
                font-size: $font-size-sm;
                margin-top: 5px;
                font-weight: $font-weight-regular;
                @include clearfix();
            }
        }
    }

    .analytic-tab-content {
        padding: 15px;

        .package-statuses {

            .row:not(:last-child) > :last-child {
                margin-bottom: 15px;
            }

            .row > :last-child {
                text-align: right;
            }

            .label {
                font-size: $font-size-sm;
                color: $text-light;
            }

            .icon {
                font-size: $font-size-2;
                vertical-align: middle;
                margin-right: 8px;

                &--pending {
                    color: $brand-warning;
                }

                &--accepted {
                    color: $brand-success;
                }

                &--rejected {
                    color: $brand-danger;
                }
            }

            .price {
                font-size: $font-size;
                color: $text-light;
                font-weight: $font-weight-semi-bold;

                .currency {
                    color: $text-lighter;
                    margin-left: 5px;
                }
            }

            &--total {
                border-top: 1px dashed $brand-gray-3;
                padding-top: 10px;

                .label {
                    font-size: $font-size;
                    color: $text-light;
                    font-weight: $font-weight-semi-bold;
                }

                .price {
                    font-size: $font-size-2;

                    .currency {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
@media (max-width: 739px) {
    .statistic-date-filter{
        width: 100%;

        .col-stat-4{
            width: 33.3%;
            padding-left: 0;
        }

        .col-stat-btn{
            padding: 0;
        }

        .filter-statistics-button{
            width: 100%;
        }
    }
}
