@import 'assets/styles/base/_variables';

.notification-item{
	margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

	&__content{
		position: relative;

		&__icon{
			position: absolute;
			left: 10px;
			top: -1px;
			font-size: 16px;

			&.success{
				color: lighten($brand-success, 15%);
			}
			&.danger{
				color: lighten($brand-danger, 15%);
			}
			&.info{
				color: lighten($brand-info, 15%);
			}
			&.warning{
				color: lighten($brand-warning, 15%);
			}
		}

		&__text{
			padding-left: 30px;
			font-size: $font-size-sm;
			color: $text-light;

			.bold{
				font-weight: $font-weight-semi-bold;
			}

			.small{
				font-size: $font-size-xs;
				color: $text-lighter;
			}

            &__time {
                color: $text-lighter;
                font-size: 10px;
                margin-top: 3px;
                font-weight: $font-weight-light;
            }
		}
	}

	&__link{
		text-align: right;
		font-weight: $font-weight-semi-bold;

        .link-style {
            font-size: $font-size-xs;
            line-height: normal;
            color: $brand-primary;
        }

		i {
            font-size: 130%;
			vertical-align: text-top;
		}
	}
}

.notification-item {
    &--unseen {
        .notification-item__content__text{
            .garage {
                font-weight: $font-weight-semi-bold;
            }
            .created-by {
                font-weight: $font-weight-semi-bold;
            }
        }

        .notification-item__content__icon {
            &.success {
                color: $brand-success;
            }

            &.danger {
                color: $brand-danger;
            }

            &.info {
                color: $brand-info;
            }

            &.warning {
                color: $brand-warning;
            }
        }
    }
}

.notifications-segment {
    margin-bottom: 15px;

    &__title {
        font-size: $font-size-xs;
        color: $text-lighter;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 7px;
    }
}
