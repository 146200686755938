@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.analytic-tab.analytic-tab-checklist{

    .analytic-tab-header{
        cursor: pointer;
        border-bottom: solid 1px $brand-gray-3;

        &__package-stat{
            font-weight: $font-weight-semi-bold;
            text-align: center;
            margin: 11px 0;
            border-right: 1px solid $brand-gray-3;
            border-left: 1px solid $brand-gray-3;

            &:first-child{
                border-left: none;
            }
            &:last-child{
                border-right: none;
            }

            &--default{
                color: $brand-primary;
            }
            &--success{
                color: $brand-success;
            }

            .icon{
                font-size: $font-size-2;
            }
            .value{
                font-size: 26px;
                color: $text-light;
                margin-left: 8px;
            }
            .label{
                font-size: $font-size-xs;
            }
        }

    }

}
