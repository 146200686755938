@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.tires-pick-container {

    .dd-container {
        margin: 0;

        &.dd-sm {
            width: 100%;
            display: inline-block;
            min-width: unset;

            .dd-select {
                padding: 4px 18px 4px 10px;
                min-height: 25px;
            }
        }

        .dd-header {
            &__icon {
                right: 10px;
                font-size: 10px;
            }
        }
    }

    .custom-checkbox {
        margin-bottom: 12px;
    }

    .tires-axels {

        .tires-axel-item {
            margin-bottom: 10px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 30px;
            }
        }
    }

    .tires-select {
        .tires-filters {
            margin-bottom: 30px;

            .dd-container, .form-group {
                width: 100%;
                margin: 0;
            }

            .tires-filters-axel {
                line-height: 30px;
            }
        }

        .tires-select-content {
            height: 60vh;
            overflow: auto;
            padding-top: 30px;

            @media (max-width: $screen-xs-max) {
                height: 50vh;
            }
        }

        .tires-list-header {
            @include clearfix;
            margin-bottom: 20px;
            padding-right: 10px;
            font-size: 12px;
            padding-left: 30px;
            font-weight: 600;

            @media (max-width: $screen-xs-max) {
                font-size: $font-size-xs;
                padding-left: 0;
            }

            .tires-sort-item {
                cursor: pointer;

                i {
                    font-size: $font-size-xs;
                    color: $text-lighter;
                }

                &.active {
                    color: $brand-primary;

                    i {
                        color: $brand-primary;
                    }
                }
            }
        }

        .tires-list {
            padding-right: 10px;

            .tires-item {
                margin-bottom: 15px;

                label {
                    width: 100%;
                    line-height: 21px;
                }

                .tires-item-radio {
                    @include clearfix;
                    font-size: $font-size-sm;

                    @media (max-width: $screen-xs-max) {
                        font-size: $font-size-xs;
                    }

                    &__name {
                        float: left;
                    }

                    &__price {
                        text-align: right;
                        font-size: 115%;
                        font-weight: $font-weight-semi-bold;
                    }
                }
            }
        }

        .tires-list-empty {
            text-align: center;
            color: $text-light;
            font-weight: $font-weight-light;
        }

        .tires-axel-tab {
            .tires-axel-tabs {
                .tires-axel-tab-item {
                    display: inline-block;
                    width: calc(50% - 40px);
                    padding: 10px;
                    text-align: center;
                    color: $text-light;
                    cursor: pointer;
                    background-color: #e1e3e64d;
                    border-radius: 8px;
                    margin: 0 20px;

                    &.active {
                        color: #FFF;
                        font-weight: $font-weight-semi-bold;
                        background-color: $brand-primary;
                    }

                    @media (max-width: $screen-xs-max) {
                        font-size: $font-size-sm;
                    }
                }
            }
        }
    }

    .tires-selected-edit {
        margin-bottom: 20px;

        .edit {
            font-size: $font-size;
            line-height: normal;
            margin-left: 5px;
            margin-right: 10px;
        }

        ul {
            list-style: none;
            font-size: $font-size-sm;
            padding-left: 50px;
        }
    }

    .tires-step-submit {
        margin-top: 20px;
    }
}
