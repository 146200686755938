@import 'assets/styles/base/_variables';

.error-recovery-container {
    text-align: center;

    .error-recovery-message {
        font-size: $font-size-2;
        color: $text-light;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .btn {
        display: inline-block;
        width: auto;
    }
}
