@import 'assets/styles/base/_variables';

.confirm-page {
    margin: 100px auto;

    @media (min-width: $screen-sm-min) and (min-height: 500px) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }

    img {
        width: 90px;
    }
}
