@import "assets/styles/base/_variables";

.price-update{
    .service-tab {
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
        background-color: rgba(143, 156, 165, 0.15);
        padding: 35px 10px;
        font-size: $font-size-2;
        color: $text-light;
        cursor: pointer;
        font-weight: $font-weight-semi-bold;
        border: none;

        &:hover {
            background-color: rgba(143, 156, 165, 0.05);
            box-shadow: none;
        }

        &__title{
            margin-bottom: 5px;
        }

        &__tip{
            font-size: $font-size-2;
            font-weight: $font-weight-light;
        }
    }
}
