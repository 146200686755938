@import 'assets/styles/base/_variables';
@import "assets/styles/base/_mixins";

.profile-progress-container {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FFF;
    width: 100%;
    padding: 15px 0;
    z-index: 100;
    border-radius: $border-radius $border-radius 0 0;
    @include clearfix();
}

.profile-progress-container-cover {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 90;
}

.profile-progress-range {
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;

    &__fill{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: $brand-success;
        color: #FFF;
        font-size: 12px;
        line-height: 15px;
        font-weight: $font-weight-semi-bold;
        text-align: center;
        border-radius: 0 $border-radius $border-radius 0;
        transition: all 0.3s;
        width: 0;
    }
}

.profile-progress-item {
    margin-bottom: 10px;
}

.profile-progress-item-header {
    margin-bottom: 10px;
    &__title {
        font-size: $font-size-2;
        font-weight: $font-weight-semi-bold;
    }
}

.profile-progress-item-list {
    margin-bottom: 15px;

    &__item {
        font-size: $font-size;
        margin: 5px 0;

        .loader-loading {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }

        .filled {
            color: $text-lighter;
        }

        .unfilled {
            a {
                color: $text-color;
                font-size: $font-size;
                line-height: normal;
            }
            i {
                color: $brand-warning;
            }
        }

        i {
            margin-right: 5px;
        }
    }
}

.profile-progress-text {
    margin-bottom: 20px;
    text-align: center;
    color: $text-lighter;
    font-size: $font-size;

    .link-style {
        font-size: $font-size;
        line-height: normal;
    }
}
