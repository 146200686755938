.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float:none !important;
}

.link-style{
    background-color: transparent;
    color: $brand-primary;
    text-decoration: none;
    font-size: $font-size-sm;
    line-height: 27px;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
        color: $btn-primary-hover;
        text-decoration: none;
    }

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    &:active {
        color: $btn-primary-active;
    }

    &:active {
        color: $btn-primary-active;
    }

    &[disabled] {
        color: $btn-primary-disabled;
        cursor: not-allowed;
    }
}

.show {
    display: block !important;
}

.hide {
    display: none;
}

.invisible {
    visibility: hidden;
}

.text-right {
    text-align: right !important;
}

@media (max-width: $screen-xs-max) {
    .text-right-xs {
        text-align: right !important;
    }
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.pull-l {
    float: left !important;
}

.pull-r {
    float: right !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-3 {
    margin-top: 3px !important;
}
.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mh-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mh-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mh-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mh-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}


.mv-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mv-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.mv-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}

.mv-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.mv-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.p-0 {
    padding: 0 !important;
}

.ph-30 {
    padding: 0 30px !important;
}

.pv-30 {
    padding: 30px 0 !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.static {
    position: static !important;
}

.overflow-hidden {
    overflow: hidden;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-size-xs{
    font-size: $font-size-xs;
}

.font-size-sm{
    font-size: $font-size-sm;
}

.font-size-default{
    font-size: $font-size !important;
}

.font-size-2{
    font-size: $font-size-2;
}

.font-size-4{
    font-size: $font-size-4;
}

.font-weight-light {
    font-weight: $font-weight-light;
}
.font-weight-regular {
    font-weight: $font-weight-regular !important;
}
.font-weight-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.light {
    font-weight: $font-weight-light;
}
.bold {
    font-weight: $font-weight-semi-bold;
}

.vertical-align-sub{
    vertical-align: sub;
}

.line-height-0 {
    line-height: 0 !important;
}

.line-height-inherit {
    line-height: inherit !important;
}

.text-lighter {
    color: $text-lighter;
}

.text-light {
    color: $text-light;
}

.text-color {
    color: $text-color;
}

.centered-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 32px;
    color: $text-lighter;
    text-align: center;

    div{
        font-size: $font-size;
        font-weight: $font-weight-light;
    }

    &--relative{
        position: static;
        transform: none;
        padding: 20px 0;
    }
}

.loading{
    color: $text-light;
    text-align: center;
    font-size: $font-size;
    font-weight: $font-weight-light;
    overflow: hidden;
}

.float-none{
    float: none !important;
}
.float-left{
    float: left !important;
}
.float-right{
    float: right !important;
}

.half-line-divider{
    height: 2px;
    width: 25px;
    margin: 10px 0;
    background-color: #000;
    opacity: 0.8;
}

.no-border{
    border: none !important;
}

.line-height-form-group {
    line-height: 68px;
}
.bottom-sticky-space {
    padding-bottom: 70px;
}

.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hr {
    border-color: $border-color;

    &--dashed {
        border-style: dashed;
    }
}

.relative{
    position: relative;
}

.brand-primary{
    color: $brand-primary;
}
.brand-warning{
    color: $brand-warning;
}
.brand-danger{
    color: $brand-danger;
}
.brand-success{
    color: $brand-success;
}

.word-hr-separator {
    border-top: 1px dashed $brand-gray-3;
    position: relative;
    margin: 20px 0;

    span {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -55%);
        line-height: normal;
        background: #ffffff;
        padding: 0 10px;
        font-size: 10px;
        color: $text-lighter;
    }
}

.vertical-text-delimiter {
    color: $text-lighter;
    font-weight: $font-weight-light;
    margin: 0 2px;
}

.vertical-line-delimiter {
    width: 1px;
    background-color: $border-color;
    height: 100vh;
    margin: auto;
    position: absolute;
    left: 50%;
}

.submit-btn-padd{
    padding: 0 $submit-button-padding;
}

.total-border{
    border-bottom: 1px solid $brand-primary;
}

.closer-to-form-data {
    margin-top: -15px;
}

@media (max-width: $screen-xs-max) {
    .hide-on-mobile {
        display: none !important;
    }
}
