@import 'assets/styles/base/_variables';

.licence-plate {
    position: relative;

    label {
        margin: 0 0 10px 0;
        text-align: center;
        display: block;
    }
}

.licence-plate-input {
    width: 100%;
    max-width: 200px;
    margin: auto;
    position: relative;

    > .form-group.licence-plate-group {
        float: left;
        margin-top: 0;
    }

    > .form-group {

        &.licence-plate-group {
            &__canton {
                width: 40%;

                input {
                    text-transform: uppercase;
                    border-right: none;
                    border-radius: 5px 0 0 5px;
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: -2px;
                    width: 4px;
                    height: 4px;
                    transform: translateY(-50%);
                    z-index: 1;
                    background-color: #000;
                    border-radius: 4px;
                }
            }
        }

    }

    > .form-group.licence-plate-group {
        &__number {
            width: 60%;

            input {
                border-left: none;
                border-radius: 0 5px 5px 0;
            }
        }
    }

    &--middle-dot {
        position: absolute;
        top: 50%;
        left: 40%;
        width: 4px;
        height: 4px;
        transform: translateY(-50%);
        z-index: 1;
        background-color: #000;
        border-radius: 4px;
    }

    &--focus {
        input:not(.input-error) {
            border: $form-input-focus-border;
        }
    }
}

.licence-plate-validation {
    width: 40%;
    float: left;
    padding-left: 10px;
    position: relative;
    height: 67px;
}

.licence-plate-validation-wrapper {
    transform: translateY(-50%);
    position: relative;
    top: 50%;

    > p {
        opacity: 0.5;
        margin: 0;
        color: $text-color;
        font-size: $font-size;
        font-weight: $font-weight-light;

        > span {
            color: $btn-disabled;
            position: relative;
            top: 2px;
            left: -5px;
            font-size: $font-size-2;
        }

        &:first-child {
            line-height: 1.65;
        }
    }

    > p.valid {
        opacity: 1;

        > span {
            color: $brand-primary;
        }
    }

    > p.non-valid {
        opacity: 1;
        color: $alert-danger-primary;
        font-size: $font-size;

        > span {
            color: $alert-danger-primary;
        }
    }
}

.licence-plate-tooltip {
    position: absolute;
    right: 20px;
    display: inline-block;

    .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: #555;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        top: 125%;
        left: 50%;
        margin-left: -200px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip-text::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
}

.empty-canton-flag {
    width: 16px;
    height: 16px;
    background-color: $brand-gray-3;
}

.canton-flag-radius {
    border-radius: 0 0 16px 16px;
}

.licence-plate-form-group {
    width: 100%;
    max-width: 200px;
    margin: auto;
    position: relative;

    label {
        display: block;
        text-align: center;
    }

    input {
        &::placeholder {
            text-align: center
        }
    }
}
