@import "assets/styles/base/_variables";

.appointments-container {
    margin-bottom: 20px;
}

.appointments-list {
    text-align: center;
    font-size: $font-size-sm;
    margin-bottom: 50px;
}

.appointments-header {
    font-weight: $font-weight-semi-bold;
}

.appointments-item {
    margin-bottom: 10px;
    line-height: 30px;

    .time-picker-custom {
        padding: 5px 10px;
        height: 30px;
        min-height: 30px;
        font-size: $font-size-sm;
    }

    .custom-checkbox {
        margin-top: 5px;
        line-height: normal;
    }
}

.appointments-container {

    .react-datepicker__tab-loop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1000;

        .react-datepicker-popper {
            width: 90%;
            max-width: 420px;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;

            .react-datepicker {
                width: 100%;

                .react-datepicker__triangle {
                    display: none;
                }

                .react-datepicker__navigation--previous {
                    top: 15px;
                }
                .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
                    right: 10px;
                    top: 15px;
                }

                .react-datepicker__month-container {
                    width: 100%;

                    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                        width: calc(13% - 0.166rem);
                        line-height: 2.5rem;

                        &--selected {
                            background-color: $brand-primary;
                        }
                    }
                }
                .react-datepicker__time-container {
                    width: 100%;

                    .react-datepicker__header {
                        border-bottom: none;

                        .react-datepicker-time__header {
                            display: none;
                        }
                    }

                    .react-datepicker__time {
                        .react-datepicker__time-box {
                            width: auto;

                            .react-datepicker__time-list {

                                height: calc(150px + (1.7rem / 2)) !important;
                                overflow-y: auto;
                                overflow-x: hidden;

                                .react-datepicker__time-list-item.time-picker-time-custom {
                                    height: auto;
                                    display: inline-block;
                                    border-radius: 0.3rem;
                                    margin: 0.166rem;
                                }

                                .react-datepicker__time-list-item {
                                    &--selected {
                                        background-color: $brand-primary;
                                    }
                                }

                                .react-datepicker__time-list-item.time-picker-time-custom.react-datepicker__time-list-item--disabled {
                                    display: none;
                                }
                            }
                        }
                    }

                }
            }
        }
    }

}
