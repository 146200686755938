@import 'assets/styles/base/_variables';

.activity-item {
    &__sms-user{
        color: $text-light;
        background-color: $brand-gray-3;
        padding: 10px 15px;
        border-radius: 0 20px 20px 20px;
        margin-top: 5px;
    }
}
