@import "assets/styles/base/_variables";

.react-datepicker-wrapper{
    display: block;
}

.react-datepicker.react-datepicker--time-only{

    .react-datepicker__header{
        &--time{
            display: none;
        }
    }

    .react-datepicker__time-container{
        width: 200px;

        .react-datepicker__time-box{
            width: auto;
        }
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
        font-size: $font-size-2;

        &.react-datepicker__time-list-item--selected{
            background-color: $brand-primary;
        }
    }
}
