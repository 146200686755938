@import 'assets/styles/base/_variables';
@import 'assets/styles/base/_mixins';

.btn {
	border: 0;
	margin: 10px 0;
	border-radius: 31px;
	text-align: center;
	display: inline-block;
	outline: 0 !important;
	box-sizing: border-box;
	transition: 0.2s ease-in;
	padding: 8px 12px;
	font-weight: $font-weight-semi-bold;
	line-height: normal;
    cursor: pointer;
    position: relative;
    min-width: 70px;

	&--rounded {
		border-radius: 31px;
	}

	&[disabled] {
		cursor: not-allowed;

		&:hover,
		&:active,
		&:focus {
			outline: 0 !important;
		}
	}

    &__icon{
        vertical-align: middle;
        margin-left: 5px;
    }
}

.btn-primary {
	@include button-bg($btn-primary, #FFFFFF);

    &:hover {
        background-color: $btn-primary-hover;
    }

    &:active {
        background-color: $btn-primary-active;
    }

    &:focus {
        box-shadow: 0 0 3px 0 $btn-primary;
    }

    &:disabled {
        background-color: $btn-primary-disabled;
    }

    &--outline{
        background-color: transparent;
        border: 1px solid $btn-primary;
        color: $btn-primary;

        &:hover {
            background-color: transparent;
            border: 1px solid $btn-primary-hover;
            color: $btn-primary-hover;
        }
        &:active {
            background-color: transparent;
            border: 1px solid $btn-primary-active;
            color: $btn-primary-active;
        }
        &:focus {
            background-color: transparent;
            border: 1px solid $btn-primary-hover;
            color: $btn-primary-hover;
            box-shadow: 0 0 3px 0 $btn-primary;
        }
        &:disabled {
            background-color: transparent;
            border: 1px solid $btn-primary-disabled;
            color: $btn-primary-disabled;
        }
    }
}

.btn-secondary {
	@include button-bg($btn-secondary, $btn-primary);
    border-radius: 4px;

    &:hover {
        background-color: $btn-secondary-hover;
    }

    &:active {
        background-color: $btn-secondary-active;
    }

    &:focus {
        box-shadow: 0 0 3px 0 $btn-secondary;
    }

    &:disabled {
        background-color: $btn-secondary-disabled;
    }

    &--outline{
        background-color: transparent;
        border: 1px solid $btn-secondary;
        color: $btn-primary;

        &:hover {
            background-color: transparent;
            border: 1px solid $btn-secondary-hover;
            color: $btn-primary-hover;
        }
        &:active {
            background-color: transparent;
            border: 1px solid $btn-secondary-active;
            color: $btn-primary-active;
        }
        &:focus {
            background-color: transparent;
            border: 1px solid $btn-secondary-hover;
            color: $btn-primary-hover;
            box-shadow: 0 0 3px 0 $btn-secondary;
        }
        &:disabled {
            background-color: transparent;
            border: 1px solid $btn-secondary-disabled;
            color: $btn-primary-disabled;
        }
    }
}

.btn-success {
	@include button-bg($btn-success, $btn-secondary);
}

.btn-danger {
	@include button-bg($btn-danger, $btn-secondary);

    &:hover {
        background-color: $btn-danger-hover;
    }

    &:active {
        background-color: $btn-danger-active;
    }

    &:focus {
        box-shadow: 0 0 3px 0 $btn-danger;
    }

    &:disabled {
        background-color: $btn-danger-disabled;
    }

    &--outline{
        background-color: transparent;
        border: 1px solid $btn-danger;
        color: $btn-danger;

        &:hover {
            background-color: transparent;
            border: 1px solid $btn-danger-hover;
            color: $btn-danger-hover;
        }
        &:active {
            background-color: transparent;
            border: 1px solid $btn-danger-active;
            color: $btn-danger-active;
        }
        &:focus {
            background-color: transparent;
            border: 1px solid $btn-danger-hover;
            color: $btn-danger-hover;
            box-shadow: 0 0 3px 0 $btn-danger;
        }
        &:disabled {
            background-color: transparent;
            border: 1px solid $btn-danger-disabled;
            color: $btn-danger-disabled;
        }
    }
}

.btn-warning {
	@include button-bg($btn-warning, $btn-secondary);
}

.btn-sm, .next-to-btn-sm {
	font-size: 12px;
	padding: 6px 15px !important;
}

.btn-md, .next-to-btn-md {
    font-size: $font-size;
    line-height: 22px;
    padding: 10px 40px 10px !important;

    @media (max-width: $screen-xs-min) {
        padding: 10px 10px !important;
    }
}
.btn-lg, .next-to-btn-lg {
    font-size: $font-size;
    line-height: 22px;
    padding: 13px 35px 13px !important;
}

.btn-lg {
	width: 100%;
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-round {
    border-radius: 31px;

    &--sm{
        border-radius: 4px;
    }
}

.btn-shadow {
    border: none;
    box-shadow: 0 0 6px 0 rgba(45, 175, 255, 0.4);

    &:hover, &:active, &:focus, &:disabled {
        border: none;
        box-shadow: 0 0 6px 0 rgba(45, 175, 255, 0.4);
    }

    &--gray{
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    }
}

.btn-dark {
	background-color: $btn-dark;
	color: #fff;
}

.btn a {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
}

.btn-link {
    border: none;
    background-color: transparent;

    &:hover {
        border: none;
        background-color: transparent;
        color: $btn-danger-hover;
    }
    &:active {
        border: none;
        background-color: transparent;
        color: $btn-danger-active;
    }
    &:focus {
        border: none;
        background-color: transparent;
        color: $btn-danger-hover;
    }
    &:disabled {
        border: none;
        background-color: transparent;
        color: $btn-danger-disabled;
    }
}

.next-to-btn-sm, .next-to-btn-md, .next-to-btn-lg {
    display: inline-block;
    margin: 10px 0;
}
