@import 'assets/styles/base/_variables';

.footer-icons{
	padding: 0 20px;
	border-top: 1px solid $border-color-light;
	position: fixed;
	bottom: 0;
    left: 50%;
    transform: translateX(-50%);
	background-color: #FFF;
	display: table;
	width: 100%;
	z-index: 50;
    box-shadow: 0 -4px 8px 0 rgba(191, 191, 191, 0.29);

	&__icon{
        cursor: pointer;
		display: block;
		line-height: normal;
		margin: 0;
		float: left;
		width: 25%;
		text-align: center;
		color: $text-color;
        padding: 7px 0;

        @media (min-width: $screen-sm-min) {
            padding: 12px 0 12px;
        }

        &:hover,
        &:active,
        &:focus {
            color: $brand-primary;
        }

        &.active {
            color: $brand-primary;
        }

		i{
			font-size: 25px;
			margin-bottom: 6px;
			display: inline-block;
		}

		span {
			font-size: 10px;
			font-weight: 600;
			display: block;
			margin-top: -5px;
		}

        @media (min-width: $screen-sm-min) {
            i{
                font-size: 27px;
            }

            span {
                font-size: 12px;
            }
        }
	}
    .message-bubble {
        border-radius: 50%;
        background-color: red;
        color: white;
        width: 24px;
        height: 24px;
        display: inline-block;
        line-height: 24px;
        position: relative;
        left: 10px;
        bottom: 8px;
    }

    .message-bubble-invisible {
        border-radius: 50%;
        background-color: transparent;
        color: white;
        width: 24px;
        height: 24px;
        display: inline-block;
        line-height: 24px;
        position: relative;
        left: 10px;
        bottom: 8px;
    }

    .news-footer {
        .icon-bell {
            position: absolute;
        }
        span {
            margin-top: 4px;
        }
    }
}
