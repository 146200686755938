@import "assets/styles/base/_variables";

.form-container{

  &__header{
	padding-bottom: 10px;

	&--border-bottom{
	  margin-bottom: 40px;
	  border-bottom: 1px solid #ebebeb;
	}
  }

  &__progress-container{
	position: relative;

	&__line{
	  height: 1px;
	  background-color: #ddd;
	}

	&__bar{
	  position: absolute;
	  left: 0;
	  top: -2px;
	  height: 4px;
	  background-color: $brand-primary;
	  border-radius: 5px;
	  width: 20%;
	  transition: width 0.3s;
	}
  }
}

.car-data-complete{
  border: 1px solid #ebebeb;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(159, 159, 159, 0.28);
  padding: 20px;

  &__logo{
	width: 55px;
	height: 55px;
	position: relative;
	border: 1px solid #ebebeb;
	border-radius: 5px;

	img{
	  position: absolute;
	  top: 50%;
	  -webkit-transform: translate(-50%,-50%);
	  transform: translate(-50%,-50%);
	  left: 50%;
	  width: 40px;
	  height: 40px;
	  object-fit: contain;
	}
  }

  &__info{
	font-weight: 600;
	color: $text-light;
	font-size: $font-size;
	margin-top: 8px;
  }
}

.licence-plate-loader{
  text-align: center;
  padding: 80px 30px 0;

  &__animation{
	margin-bottom: 100px;

	img{
	  width: 20px;
	  vertical-align: bottom;
	  margin-right: 5px;
	}

	&__label{
	  font-weight: 600;
	  color: $text-light;
	  font-size: $font-size;
	}
  }

}

.back-btn {
  width: 35px;
  height: 35px;
  padding: 0 !important;
  font-size: 30px;
  i {
	font-weight: bold;
  }
}
/* dark mode */
#root-copy {
  @media (min-width: $screen-md-min) {
	top: 0;
	position: absolute;
	left: 85%;
  }

  .tab-default {
	background-color: #212b36;

	form {
	  background: transparent;
	}

	.dd-container label,
	.input-control label {
	  color: #fff;
	}

	.dd-select--error {
	  color: red;
	}

	.dd-select--disabled {
	  color: rgba(33, 43, 54, 0.7);
	  background-color: #c4cdd5;
		cursor: not-allowed;
	}
  }
}

.source-provider{
  font-size: 12px;
  margin-top: 5px;
  color: $text-light;
  font-weight: 500;
}

.form-item{
	padding: 35px 40px;
	border-radius: 8px;
	border: 1px solid $border-color-light;
	font-size: $font-size-4;
	text-align: center;
	color: $text-color;
	margin-bottom: 15px;
	min-height: 140px;
	cursor: pointer;

	&:hover {
		border-color: $brand-primary;
	}

	div:last-child {
		font-weight: $font-weight-light;
	}
}
