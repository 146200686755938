@import 'assets/styles/base/_variables';
@import "assets/styles/base/_mixins";

.checklist-labour-header {
    @include clearfix();

    text-align: center;
    font-size: 13px;
    margin-bottom: 5px;
}
