@import 'assets/styles/base/_variables';
@import "assets/styles/base/_mixins";

.checklist-labour {
    @include clearfix();
    padding: 10px 0;

    &:nth-child(even) {
        background: #fafafa;
    }

    .custom-checkbox-label {

        input {
            width: 22px;
            height: 22px;
        }

        .custom-checkbox--checkbox {
            background: #fff;
            width: 22px;
            height: 22px;
            top: -4px;

            .loader-spinner {
                margin: 3px;
                width: 15px;
                height: 15px;
            }
        }

        input:checked + .custom-checkbox--checkbox span {
            font-size: $font-size;
        }
    }

    &__name {
        font-size: 13px;
        color: $text-light;

        a, .link-style {
            line-height: normal;
            margin-left: 5px;
        }
    }

    &__btn {
        text-align: center;
    }
}

.checklist-labour-position {
    margin-bottom: 5px;
}

.checklist-labour-position .checklist-labour-position {
    padding: 10px 0 0 30px;
}

.checklist-labour-list-item {
    margin-bottom: 10px;
}

.mastercheck-toogle{
    width: 60px;
}
