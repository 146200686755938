@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.package-item {
    border-radius: 8px;
    box-shadow: $package-shadow;
    margin-bottom: 15px;

}

.package-item-content {
    padding: 15px;

    &__title {
        display: block;
        color: $text-color;
        font-size: $font-size;
        font-weight: $font-weight-semi-bold;

        @include clearfix();

        & > :first-child {
            float: left;
            width: 80%;
        }

        & > :last-child {
            float: right;
            font-size: $font-size-sm;
            color: $text-lighter;
            width: 20%;
            text-align: right;
        }
    }

    &__parts {
        color: $text-lighter;
        margin-bottom: 10px;
    }

    &__total {
        @include clearfix();
        margin-top: 10px;

        & > :first-child {
            float: left;
            color: $text-light;
        }

        & > :last-child {
            float: right;
            font-weight: $font-weight-semi-bold;
            color: $text-color;
        }
    }

    ul {
        padding-left: 20px;
        list-style: none;
    }
}

.package-item-footer {
    border-top: 1px solid $border-color-light;
    font-weight: $font-weight-light;
    @include clearfix();

    button {
        outline: none;
        border: 0;
        background: transparent;
        color: $brand-primary;
        font-size: $font-size-sm;
        line-height: 27px;
        cursor: pointer;

        &:hover {
            background: transparent;
            outline: none;
            border: 0;
            color: $btn-primary-hover;
        }
    }

    & > * {
        display: inline-block;
        text-align: center;
        font-weight: $font-weight-semi-bold;
        padding-top: 8px;
        padding-bottom: 8px;

        i {
            font-size: $font-size-2;
            vertical-align: text-top;
        }
    }
}

.extend-package-list {
    &__item {
        @include clearfix();
        padding-bottom: 15px;

        &:last-child {
            padding-bottom: 0;
        }

        & > :nth-child(1) {
            float: left;
            max-width: 90%;
        }

        & > :nth-child(2) {
            float: right;

            &.link-style {
                font-weight: $font-weight-semi-bold;
                line-height: normal;
                max-width: 30%;
            }
        }
    }

    .extend-package-list {
        margin-left: 30px;
        clear: both;
        position: relative;
        top: 10px;
    }
}

.missing-info {
    margin-top: 10px;
    font-size: $font-size;
    color: $brand-warning;
    font-weight: $font-weight-semi-bold;
}
