.settings-tab {

    padding: 0 10px;
    margin-bottom: 50px;

    &__header {
        padding: 10px 0;
        color: $text-light;
        margin-bottom: 10px;
        font-weight: $font-weight-semi-bold;

        &__description {
            font-weight: normal;
        }
    }

    .custom-checkbox {
        margin-bottom: 10px;
    }
}
