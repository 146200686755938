@import "assets/styles/base/_variables";

.react-tel-custom {

    .form-group {

        .react-tel-input {
            .form-control {
                display: block;
                width: 100%;
                border-radius: $border-radius;
                border: $form-input-border;
                background-color: $form-input-background;
                min-height: 40px;
                height: auto;
                padding: 10px 16px 10px 80px;
                outline: 0;
                font-weight: $font-weight-light;
                box-shadow: none;
                font-size: $font-size;

                &.input-error {
                    border: 1px solid $brand-danger;
                    background-color: $form-error-background;
                    color: $brand-danger;
                }
            }

            .flag-dropdown {
                border-right: $form-input-border;

                .arrow {
                    font-family: 'icomoon' !important;
                    color: $text-lighter;
                    border: none;
                    font-size: 11px;
                    transform: translateY(-50%);
                    margin-top: -3px;
                    left: 32px;

                    &:before {
                        content: "\e908";
                    }
                }

                .selected-flag {
                    width: 68px;
                    padding: 0 0 0 18px;
                }
            }

            .country-list {
                overflow-y: auto;
            }
        }
    }
}
