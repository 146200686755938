@import 'assets/styles/base/_variables';

.car-detail {
    > .row{
        cursor: pointer;
    }

    &__item-logo {
        width: 42px;
        height: 42px;
        object-fit: contain;
        position:absolute;
    }

    &__make {
        display: block;
    }

    &__icon {
        text-align: right;
        font-size: $font-size;
        color: $text-lighter;
        font-weight: $font-weight-semi-bold;

        i {
            font-size: $font-size-sm;
        }
    }

    &__sub{
        font-size: 12px;

        &__label{
            font-weight: $font-weight-light;
            line-height: 20px;
        }

        &__value{
            text-align: left;
            line-height: 20px;
            font-weight: $font-weight-semi-bold;
        }
    }
}
