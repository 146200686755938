@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.packages-title{
    border-bottom: 1px solid $border-color-light;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.packages-total{
    @include clearfix();
    margin: 15px 0 50px;
    font-weight: $font-weight-semi-bold;

    & > :first-child{
        float: left;
    }

    & > :last-child{
        float: right;
    }
}

.packages-item{
    margin-bottom: 10px;
}

.packages-urgency-1 {color: $packages-urgency-1}
.packages-urgency-2 {color: $packages-urgency-2}
.packages-urgency-3 {color: $packages-urgency-3}
