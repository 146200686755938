@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.dd-container {
	margin: $form-margin;
	position: relative;
	outline: 0;
	width: 100%;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.dd-filter-input {
		padding: 0 0 0 20px;
		height: auto;
	}
}

.dd-title {
    word-wrap: break-word;
    display: inline-block;
    color: $form-input-label-color;
    font-size: $form-input-label-size;
    margin-left: 16px;
    margin-bottom: 6px;

	&--disabled {
        color: $form-input-disabled-color-label;
		cursor: not-allowed;
	}

	&--error {
		color: $brand-danger;
	}

	&--disabled + .dd-header{
		cursor: not-allowed;
	}
}

.dd-header {
	position: relative;

	&__icon {
		cursor: pointer;
		position: absolute;
		right: 15px;
		transform: translateY(-50%);
		top: 50%;
		font-size: $font-size-sm;
	}
}

.dd-select {
	position: relative;
	font-size: $font-size;
	display: block;
	width: 100%;
	outline: 0;
	cursor: pointer;
	box-sizing: border-box;
	border-radius: $border-radius;
	border: $form-input-border;
	background-color: $form-input-background;
	min-height: 36px;
	height: auto;
	padding: 10px 16px 10px 16px;
	color: $text-lighter;
	font-weight: $font-weight-light;

	&--selected {
		color: $text-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
	}

	&--disabled {
		background-color: $form-input-disabled-background;
		border: $form-input-disabled-border;
		pointer-events: none;
		color: $form-input-disabled-color;
		cursor: not-allowed;
	}

	&--disabled + .dd-header__icon{
		color: $form-input-disabled-color;
		cursor: not-allowed;
	}

	&--error {
		border: 1px solid $brand-danger;
		background-color: $form-error-background;
		color: $brand-danger;
	}

	&--error + .dd-header__icon {
		color: $brand-danger;
	}

	&--single-item {
		cursor: default;
	}

	&--transparent {
		background-color: transparent;
		border: none;
	}

	&[readonly] {
		cursor: default;
	}

	&:not(.input-error):focus {
		border: 1px solid $brand-primary;
	}

	&::placeholder {
		color: $form-input-placeholder;
	}

    &:hover{
        &:not(.dd-select--selected){
            color: $text-lighter;
        }
    }

    &:active, &:focus {
        border: $form-input-focus-border;
    }

    &--sm {
        padding: 7px 15px 7px 15px;
        min-height: 30px;
    }
}

.popup {
    .dd-list {
        height: 60vh;
    }
}

.dd-list {
	overflow: auto;
	width: 100%;
    height: 100%;

	&__options {
		list-style-type: none;
		margin: 0;
        padding: 0 5px;
        color: $text-light;

		&--item {
            @include clearfix();

            cursor: pointer;
            padding: 15px 15px 15px 40px;
            border-bottom: 1px solid $border-color-light;
            position: relative;

			&:hover,
			&.selected {
				background-color: $form-input-disabled-background;
			}

            .custom-checkbox {
                display: inline-block;
                margin-right: 15px;
                position: absolute;
                left: 0;
                top: 15px;
            }
		}
	}
}

#make_id .dd-list {
	text-transform: uppercase;
}

.dd-no-result {
	padding: 10px 0;
	font-weight: $font-weight-regular;
    color: $text-light;

    .dd-list__options--item{
        padding-left: 0;
        padding-right: 0;
    }

	> p {
		margin: 0;
	}
}

.dd-sm {
    width: auto;
    display: inline-block;

    .dd-select {
        padding: 6px 25px 6px 15px;
        min-height: 30px;
        font-size: $font-size-sm;
    }

    .dd-header {
        &__icon {
            right: 10px;
        }
    }
}
