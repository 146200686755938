@import "assets/styles/base/_variables";

.analytic-tab.analytic-tab-car-statistic{

    .analytic-tab-header {
        padding: 20px 15px 15px;
        font-size: $font-size-sm;
        color: $text-light;
        cursor: pointer;

        .chevron{
            float: right;
            color: $text-lighter;
        }
    }

    .analytic-tab-content{
        padding: 15px;
    }
}
