@import 'assets/styles/base/_variables';

.activities-item {
    padding: 10px 20px 15px 0;
    margin-left: 20px;
    border-bottom: 1px solid $border-color;
    position: relative;
    line-height: normal;
    display: block;

    &__car {
        color: $brand-gray;
        font-size: $font-size-sm;
        margin-bottom: 4px;
    }

    &__user {
        color: $brand-gray;
        font-size: 13px;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 4px;
    }

    &__last_activity {
        color: $brand-gray;
        font-size: 11px;

        &__small{
            color: $brand-gray-2;
            margin-right: 10px;
        }
    }

    &__unseen {
        position: absolute;
        top: 13px;
        left: -20px;
        width: 10px;
        height: 10px;
        border: 1px solid $border-color;
        border-radius: 10px;

        &--has-unseen{
            background-color: $brand-primary;
            border: 1px solid transparent;
        }
    }
}
