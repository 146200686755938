@import "assets/styles/base/_variables";

.select2__control.css-yk16xz-control{
    &:hover{
        border-color: $brand-gray-3;
        color: $text-lighter;

        .css-1wa3eu0-placeholder {
            color: $text-lighter;
        }
    }

    .css-1wa3eu0-placeholder {
        color: $form-input-placeholder;
    }
}

.select2__control.select2__control--is-focused{
    &:hover{
        border-color: $brand-primary;
    }
}

.select2__control.css-yk16xz-control, .select2__control.select2__control--is-focused {

    height: 41px;
    border-radius: $border-radius;
    border: $form-input-border;
    border-color: $brand-gray-3;
    box-shadow: none;
    cursor: pointer;

    &:hover{
        color: $text-lighter;
    }

    input{
        min-height: auto;
        position: relative;
        left: -3px;
        border-right: 0;
        border-radius: 0;
    }

    .css-1uccc91-singleValue{
        color: $text-color;
        margin: 0;
    }

    .css-g1d714-ValueContainer{
        padding-left: 16px;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }
}

.select2__control.select2__control--is-focused{
    border: $form-input-focus-border;
}

.select2__option{
    background-color: #FFF !important;

    &--is-focused{
        font-weight: $font-weight-semi-bold;
    }
}

.search-user-option {
    padding: 10px 15px;

    &__name {
        font-size: $font-size;
    }

    &__sub {
        font-size: $font-size-sm;
        color: $text-light;
    }

    &:hover {
        font-weight: $font-weight-semi-bold;
        cursor: pointer;
    }
}