@import 'assets/styles/base/_variables';

.activity-action-fixed {
    position: fixed;
    bottom: 0;
    background: #FFF;
    width: 100%;
    left: 0;

    .container {
        padding: 0;
    }
}

.activity-action {
    width: 100%;
    padding: 5px 20px 0;
    border-top: 1px solid $border-color;

    &__input {
        display: inline-block;
        width: 85%;
    }

    &__btn {
        display: inline-block;
        width: 15%;
        padding-left: 4%;
        vertical-align: middle;

        .btn {
            width: 42px;
            height: 42px;
            font-size: 18px;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
