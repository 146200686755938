@import "assets/styles/base/_variables";

.react-datepicker.time-picker-custom {
    font-family: $font-default;
    border: 1px solid $border-color;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

    .react-datepicker__header {
        background-color: #FFF;
        border-bottom: 1px solid $border-color;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-range {
        background-color: $brand-primary;
        color: #FFF;
    }

    .react-datepicker__day--selected.react-datepicker__day--disabled{
        background-color: lighten($brand-primary, 15%);
        color: #ccc;
    }

    .react-datepicker__day--in-selecting-range {
        background-color: lighten($brand-primary, 15%);

        :hover{
            background-color: $brand-primary;
        }
    }

    .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end{
        background-color: $brand-primary;
    }

    .react-datepicker__triangle {
        border-bottom-color: #FFF !important;

        &::before {
            border-bottom-color: $border-color !important;
        }
    }
}
