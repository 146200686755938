@import 'assets/styles/base/_variables';

.activity-item {
    &__sms-garage{
        color: #FFF;
        background-color: $brand-primary;
        padding: 10px 15px;
        border-radius: 20px 20px 0 20px;
        margin-top: 5px;
    }
}
